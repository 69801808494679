.showNavButton {
  z-index: 2;
  cursor: pointer;
  background-color: var(--violet-dark-web-space);
  border: none;
  border-radius: 50%;
  width: 4rem;
  height: 4rem;
  padding: 0;
  position: absolute;
  top: 2.8rem;
  right: 2.4rem;
}

.showNavButton__menu {
  width: 2.133rem;
  height: 2.133rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.nav {
  opacity: 0;
  visibility: hidden;
  background-color: var(--violet-dark-web-space);
  z-index: 1;
  box-sizing: border-box;
  width: 100%;
  height: 100vh;
  max-height: 0;
  padding-left: 2.3rem;
  padding-right: 2.4rem;
  transition: max-height .3s, opacity .3s, visibility 0s .3s;
  position: fixed;
  top: 0;
  left: 0;
}

.nav__container {
  flex-direction: column;
  justify-content: space-between;
  gap: 4.7rem;
  padding-top: 3.8rem;
  display: flex;
}

.nav__info {
  flex-direction: column;
  gap: 3.1rem;
  display: flex;
}

.nav__info p {
  color: #fff9;
  font-family: Akzidenz-Grotesk Pro Regular;
  font-size: 1.6rem;
  font-weight: 400;
}

.nav__info a {
  color: #fff;
  font-family: Akzidenz-Grotesk Pro Regular;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2rem;
  text-decoration: none;
}

.nav__info1 {
  display: flex;
}

.nav__info1 div {
  max-width: 16rem;
}

.nav__info2, .nav__info1 ul {
  flex-direction: column;
  gap: 1.2rem;
  display: flex;
}

.nav__info2 ul {
  flex-direction: column;
  gap: 1.2rem;
  min-width: 29.8rem;
  display: flex;
}

.nav__info2 {
  flex-direction: column;
  display: flex;
}

.nav__socialMedia img {
  filter: brightness(0) invert();
  width: 3.2rem;
  height: 3.2rem;
}

.nav__socialMedia {
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  gap: 2.4rem;
  display: flex;
}

.nav a:hover {
  text-decoration: underline;
}

.nav.show {
  opacity: 1;
  visibility: visible;
  max-height: 100vh;
  transition: max-height .3s, opacity .3s;
}

.no-scroll {
  width: 100%;
  position: fixed;
  overflow-y: hidden;
}

.nav__info1 p {
  margin-bottom: 1.2rem;
}

.x-logo {
  width: 2.4rem !important;
  height: 2.4rem !important;
}

@media (width >= 1024px) {
  .x-logo {
    width: 4rem !important;
    height: 4rem !important;
  }

  .nav__info2 {
    row-gap: 0;
  }

  .showNavButton {
    border: none;
    width: 8rem;
    height: 8rem;
    padding: 0;
    position: absolute;
    top: 2.3rem;
    right: 11.787rem;
  }

  .showNavButton__menu {
    z-index: 1;
    width: 4.267rem;
    height: 4.267rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .nav {
    flex-direction: column;
    padding-left: 16rem;
    padding-right: 16rem;
    display: flex;
  }

  .nav__info1 > div:first-child {
    min-width: 30.7rem;
  }

  .nav__info1 > div:nth-child(2) {
    min-width: 26.8rem;
  }

  .nav__info {
    flex-direction: row;
    justify-content: space-between;
    gap: 14rem;
  }

  .nav__info p {
    color: #fff;
    align-items: center;
    margin-bottom: 2.7rem;
    font-family: Akzidenz-Grotesk Pro Med;
    font-size: 4.8rem;
    font-weight: 500;
    display: flex;
  }

  .nav__info a {
    color: #fff;
    width: fit-content;
    font-family: Akzidenz-Grotesk Pro Light;
    font-size: 3.2rem;
    font-weight: 300;
    line-height: 4rem;
    text-decoration: none;
  }

  .nav__info1 ul, .nav__info2 ul {
    gap: 4rem;
  }

  .informacion--col2 {
    flex-direction: column;
    display: flex;
  }

  .nav__socialMedia img {
    filter: brightness(0) invert();
    width: 5.6rem;
    height: 5.6rem;
  }

  .nav__socialMedia {
    align-self: self-start;
    gap: 2.5rem;
    min-width: 46.1rem;
    display: flex;
  }

  .nav__info a:hover {
    text-decoration: underline;
  }

  .nav__container {
    flex-direction: row;
    padding-top: 12.4rem;
  }

  .nav__info1 {
    gap: 9.8rem;
    display: flex;
  }
}

@media (width >= 1921px) {
  .nav__container {
    max-width: 1920px;
    margin-left: auto;
    margin-right: auto;
  }
}
/*# sourceMappingURL=index.dedad55c.css.map */
