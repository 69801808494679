.showNavButton {
	position: absolute;
	right: 2.4rem;
	top: 2.8rem;
	border: none;
	padding: 0;
	z-index: 2;
	cursor: pointer;
	width: 4rem;
	height: 4rem;
	border-radius: 50%;
	background-color: var(--violet-dark-web-space);
}

.showNavButton__menu {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 2.133rem;
	height: 2.133rem;
}

.nav {
	max-height: 0;
	opacity: 0;
	visibility: hidden;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	background-color: var(--violet-dark-web-space);
	transition: max-height 0.3s ease, opacity 0.3s ease, visibility 0s 0.3s;
	z-index: 1;
	padding-left: 2.3rem;
	padding-right: 2.4rem;
	box-sizing: border-box;
}

.nav__container {
	display: flex;
	padding-top: 3.8rem;
	justify-content: space-between;
	flex-direction: column;
	gap: 4.7rem;
}

.nav__info {
	display: flex;
	flex-direction: column;
	gap: 3.1rem;
}

.nav__info p {
	font-size: 1.6rem;
	font-weight: 400;
	font-family: "Akzidenz-Grotesk Pro Regular";
	color: #ffffff99;
}

.nav__info a {
	font-size: 1.6rem;
	font-weight: 400;
	font-family: "Akzidenz-Grotesk Pro Regular";
	color: #ffffff;
	text-decoration: none;
	line-height: 2rem;
}

.nav__info1 {
	display: flex;
}

.nav__info1 div {
	max-width: 16rem;
}

.nav__info2 {
	gap: 1.2rem;
	display: flex;
	flex-direction: column;
}

.nav__info1 ul {
	display: flex;
	flex-direction: column;
	gap: 1.2rem;
}

.nav__info2 ul {
	display: flex;
	flex-direction: column;
	gap: 1.2rem;
	min-width: 29.8rem;
}

.nav__info2 {
	display: flex;
	flex-direction: column;
}

.nav__socialMedia img {
	filter: brightness(0) invert(1);
	width: 3.2rem;
	height: 3.2rem;
}

.nav__socialMedia {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 2.4rem;
	flex-direction: row-reverse;
}

.nav a:hover {
	text-decoration: underline;
}

.nav.show {
	max-height: 100vh;
	opacity: 1;
	visibility: visible;
	transition: max-height 0.3s ease, opacity 0.3s ease;
}

.no-scroll {
	overflow-y: hidden;
	position: fixed;
	width: 100%;
}

.nav__info1 p {
	margin-bottom: 1.2rem;
}

.x-logo {
	width: 2.4rem !important;
	height: 2.4rem !important;
}

@media (min-width: 1024px) {
	.x-logo {
		width: 4rem !important;
		height: 4rem !important;
	}

	.nav__info2 {
		row-gap: 0;
	}
	.showNavButton {
		position: absolute;
		right: 11.787rem;
		top: 2.3rem;
		border: none;
		padding: 0;
		width: 8rem;
		height: 8rem;
	}

	.showNavButton__menu {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 4.267rem;
		height: 4.267rem;
		z-index: 1;
	}

	.nav {
		display: flex;
		flex-direction: column;
		padding-left: 16rem;
		padding-right: 16rem;
	}

	.nav__info1 > div:nth-child(1) {
		min-width: 30.7rem;
	}

	.nav__info1 > div:nth-child(2) {
		min-width: 26.8rem;
	}

	.nav__info {
		justify-content: space-between;
		flex-direction: row;
		gap: 14rem;
	}

	.nav__info p {
		font-size: 4.8rem;
		font-weight: 500;
		font-family: "Akzidenz-Grotesk Pro Med";
		color: #ffffff;
		margin-bottom: 2.7rem;
		display: flex;
		align-items: center;
	}

	.nav__info a {
		font-size: 3.2rem;
		font-weight: 300;
		font-family: "Akzidenz-Grotesk Pro Light";
		color: #ffffff;
		text-decoration: none;
		line-height: 4rem;
		width: fit-content;
	}

	.nav__info1 ul,
	.nav__info2 ul {
		gap: 4rem;
	}

	.informacion--col2 {
		display: flex;
		flex-direction: column;
	}

	.nav__socialMedia img {
		filter: brightness(0) invert(1);
		width: 5.6rem;
		height: 5.6rem;
	}

	.nav__socialMedia {
		display: flex;
		align-self: self-start;
		gap: 2.5rem;
		min-width: 46.1rem;
	}

	.nav__info a:hover {
		text-decoration: underline;
	}

	.nav__container {
		padding-top: 12.4rem;
		flex-direction: row;
	}

	.nav__info1 {
		display: flex;
		gap: 9.8rem;
	}
}

@media (min-width: 1921) {
	.nav__container {
		max-width: 1920px;
		margin-left: auto;
		margin-right: auto;
	}
}
